

(function($) {
 
  // Colorbox
  $('a[rel*="colorbox"]').colorbox({
    current: "zdjęcie {current} z {total}" ,
    maxWidth: "90%",
    maxHeight: "90%"
  });
    
 
}(jQuery));